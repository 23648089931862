/**
 * Taken from https://stackoverflow.com/a/18639999, so we can easily
 * make a hash of requests since we don't get a reliable one elsewhere
 * and crypto methods for md5 etc. make the bundle size HUGE
 */

let crcTable;

const makeCRCTable = function () {
  let c;
  const crcTable = [];
  for (let n = 0; n < 256; n++) {
    c = n;
    for (let k = 0; k < 8; k++) {
      c = c & 1 ? 0xedb88320 ^ (c >>> 1) : c >>> 1;
    }
    crcTable[n] = c;
  }
  return crcTable;
};

export const crc32 = function (str) {
  crcTable = crcTable || makeCRCTable();
  let crc = 0 ^ -1;

  for (let i = 0; i < str.length; i++) {
    crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xff];
  }

  return `${(crc ^ -1) >>> 0}`;
};
