<template>
  <component
    v-if="searchResponse"
    :is="productListComponent"
    :search-request-id="searchRequestId"
    :search-response="searchResponse"
    v-bind="$attrs"
  />
</template>

<script>
import { crc32 } from "../../lib/crc32.js";

export default {
  name: "CentraSearchController",
  props: {
    /**
     * The registered component to use when rendering the
     * product list
     */
    productListComponent: {
      type: String,
      default: "ProductList",
    },

    /**
     * Available fields to query can be found here
     *
     * https://docs.centra.com/swagger-ui/?api=CheckoutAPI#/5.%20product%20catalog/post_products
     */
    searchQuery: {
      type: Object,
      default: () => {},
    },

    /**
     * This prop is useful when using this component directly
     * in storyblok with a category selector
     */
    centraCategoryId: {
      type: String,
      default: undefined,
    },

    /**
     * How many products to show per page. String because of storyblok
     */
    pageSize: {
      type: String,
      default: "20",
    },

    /**
     * The page to start from (?page=X in the url overrides this). String because of storyblok
     */
    startingPage: {
      type: String,
      default: "1",
    },
  },

  watch: {
    // Watch these two we can search again when we change filters in Storyblok
    centraCategoryId() {
      this.search();
    },
    searchQuery() {
      this.search();
    },
  },

  computed: {
    /**
     * The start query which we later filter and load more from
     */
    initialQuery() {
      const query = {
        ...this.searchQuery,
        limit: this.pageSize,
        __page: this.$route.query.page ?? this.startingPage,
      };
      if (this.centraCategoryId) {
        query.categories = [this.centraCategoryId];
      }
      return query;
    },

    /**
     * An ID that can be used to identify queries on a page
     */
    searchRequestId() {
      return crc32(JSON.stringify(this.initialQuery));
    },

    /**
     * The current search response, if a search has been made
     */
    searchResponse() {
      return this.$store.state["centra-search"].searchResponses[
        this.searchRequestId
      ];
    },
  },

  serverPrefetch() {
    // SSR load products for SEO
    return this.search();
  },

  mounted() {
    // Load products if they weren't already fetched via
    // browser or SSR
    if (!this.searchResponse) {
      this.search();
    }
  },

  methods: {
    /**
     * Perform a search that this controller is based on
     */
    search() {
      if (this.searchResponse) {
        return;
      }

      const query = { ...this.initialQuery };

      this.$emit("centra-search-loading-start", {
        searchRequestId: this.searchRequestId,
      });
      return this.$store
        .dispatch("centra-search/search", {
          searchRequestId: this.searchRequestId,
          query,
        })
        .then(() =>
          this.$emit("centra-search-loading-success", {
            searchRequestId: this.searchRequestId,
          }),
        )
        .catch(() =>
          this.$emit("centra-search-loading-failed", {
            searchRequestId: this.searchRequestId,
          }),
        )
        .finally(() =>
          this.$emit("centra-search-loading-complete", {
            searchRequestId: this.searchRequestId,
          }),
        );
    },
  },
};
</script>
